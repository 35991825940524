import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Layout from 'components/layout';
import ImageHero from 'components/hero/imagehero';
import Spacer from 'components/spacer';
import {
  ImageSlider,
  Downloads,
  RegisterInterest,
  SchemeDetails,
  TitleCaptionText,
  FullImage,
} from 'components/slices';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  borderDivider: {
    position: 'relative',
    marginRight: '20px',
    '&:after': {
      content: "''",
      position: 'absolute',
      display: 'block',
      width: '1px',
      backgroundColor: theme.palette.text.lblue,
      height: '50%',
      top: '50%',
      right: '-10px',
      transform: 'translateY(-50%)',
    },
    '&:last-of-type': {
      marginRight: 0,
      '&:after': {
        display: 'none',
      },
    },
  },
  smlTxt: {
    fontSize: '0.9rem',
    lineHeight: '1.5rem',
  },
});

export const query = graphql`
  query HomeTypePageQuery($uid: String) {
    prismic {
      allHome_types(uid: $uid) {
        edges {
          node {
            _meta {
              id
              uid
              type
            }
            title
            introduction
            short_specification
            available_plots
            homes_available
            price_from
            telephone
            email
            opening_hours
            brochure {
              ... on PRISMIC__FileLink {
                url
                name
              }
            }
            banner_image
            banner_imageSharp {
              childImageSharp {
                fluid(maxWidth: 3000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            promotion_banners {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 3000, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            main_page_slideshow {
              image
              title
            }
            the_details {
              text
              title
            }
            detail_text_content
            detail_images {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 3000, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            download_block_content {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              text
              title
            }
            downloads {
              download_item {
                ... on PRISMIC__FileLink {
                  _linkType
                  url
                }
              }
              title
            }
            scheme_details {
              title
              logo_image
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              logo_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              link {
                ... on PRISMIC_Basic_page {
                  _meta {
                    uid
                    type
                    id
                  }
                }
                ... on PRISMIC_Article {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Standard_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
              }
            }
            house_types {
              house_type
            }
          }
        }
      }
    }
  }
`;

function HomeType(props) {
  const { classes } = props;
  const doc = props.data.prismic.allHome_types.edges.slice(0, 1).pop();
  if (!doc) return null;
  return (
    <Layout homeTypePageMenu={doc.node._meta}>
      <ImageHero
        data={doc.node.banner_imageSharp}
        title={doc.node.title[0].text}
        brochure={doc.node.brochure ? doc.node.brochure.url : ''}
        email={doc.node.email ? doc.node.email[0].text : ''}
        telephone={doc.node.telephone ? doc.node.telephone[0].text : ''}
        opening_hours={
          doc.node.opening_hours ? doc.node.opening_hours[0].text : ''
        }
        contactbar
      />
      <Container maxWidth="xl">
        <Spacer>
          <Grid container>
            <Box
              component={Grid}
              item
              xs={12}
              md={6}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              mb={[4, 4, 0]}
            >
              <Box
                px={
                  doc.node.title[0].text.length < 20 ? [0, 0, 20] : [0, 0, 10]
                }
              >
                <Box mb={2}>
                  <Typography component="h2" variant="h1" align="center">
                    {doc.node.title[0].text}
                  </Typography>
                </Box>
                <Typography component="p" variant="subtitle1" align="center">
                  {doc.node.short_specification[0].text}
                </Typography>
                {doc.node.homes_available && (
                  <Typography component="p" variant="subtitle1" align="center">
                    Available Homes {doc.node.available_plots[0].text}
                  </Typography>
                )}
                {doc.node.house_types && doc.node.house_types[0] && (
                  <Box display="flex" justifyContent="center">
                    {doc.node.house_types.map((item, key) => (
                      <Box key={key} className={classes.borderDivider}>
                        <Typography
                          component="p"
                          variant="subtitle1"
                          align="center"
                        >
                          {item.house_type}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
            <Box component={Grid} item xs={12} md={5}>
              <Typography component="p" variant="subtitle1" gutterBottom>
                {doc.node.introduction[0].text}
              </Typography>
              {doc.node.price_from && (
                <Box pt={2} id="price">
                  <Typography component="p" variant="h6">
                    Prices from £
                    {parseInt(doc.node.price_from).toLocaleString()}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Spacer>
        {doc.node.promotion_banners &&
          doc.node.promotion_banners[0] &&
          doc.node.promotion_banners[0].image && (
            <React.Fragment>
              {doc.node.promotion_banners.map((item, key) => (
                <Spacer key={key}>
                  <FullImage data={item} />
                </Spacer>
              ))}
            </React.Fragment>
          )}
        {doc.node.main_page_slideshow.length > 0 &&
          doc.node.main_page_slideshow[0].title && (
            <Spacer>
              <ImageSlider data={doc.node.main_page_slideshow} />
            </Spacer>
          )}
        {doc.node.the_details && doc.node.the_details[0].title && (
          <Spacer>
            <TitleCaptionText data={doc.node.the_details[0]} />
          </Spacer>
        )}
        {doc.node.detail_images && doc.node.detail_images[0].image && (
          <Spacer>
            <Box id="floorplans">
              <Grid container>
                {doc.node.detail_images.map((item, key) => {
                  return (
                    <Grid item xs={12} md key={key}>
                      <FullImage data={item} />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Spacer>
        )}
        {doc.node.detail_text_content && (
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  component="p"
                  variant="body1"
                  align="center"
                  className={classes.smlTxt}
                >
                  {doc.node.detail_text_content[0].text}
                </Typography>
              </Grid>
            </Grid>
          </Container>
        )}
      </Container>
      <Container maxWidth="lg">
        {doc.node.downloads && doc.node.downloads[0].title && (
          <Spacer>
            <Box id="downloads">
              <Downloads
                data={doc.node.downloads}
                content={doc.node.download_block_content[0]}
              />
            </Box>
          </Spacer>
        )}
        {doc.node.scheme_details.length > 0 &&
          doc.node.scheme_details[0] &&
          doc.node.scheme_details[0].title && (
            <Spacer>
              <SchemeDetails data={doc.node.scheme_details} />
            </Spacer>
          )}
        <Spacer>
          <RegisterInterest
            telephone={
              doc.node.telephone
                ? doc.node.telephone[0].text
                : process.env.DEFAULT_TELEPHONE
            }
            email={
              doc.node.email
                ? doc.node.email[0].text
                : process.env.DEFAULT_EMAIL
            }
          />
        </Spacer>
      </Container>
    </Layout>
  );
}

HomeType.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeType);
